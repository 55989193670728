import { useState, useEffect, useContext } from 'react';

import AuthContext from '../context/AuthProvider';
import { authServer } from '../api/axios';
import { Link } from 'react-router-dom';
import { getCookie } from '../util/cookie';

const GET_USERS_URL = '/get_users';

const UserManagement = () => {
    // @ts-ignore
    const { auth } = useContext(AuthContext);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const username: string = auth.username || getCookie('username');
            const authKey: string = auth.authKey || getCookie('authKey');

            try {
                const response = await authServer.post(GET_USERS_URL,
                    {
                        "username": username,
                        "authKey": authKey
                    }
                );

                setUsers(response?.data?.users || [])
            } catch (e) {
                console.log('Error disconnecting from server.', e)
            }
        }
        getUsers();
    });

    return (
        <section className="registration">
            <h1>User Management</h1>
            <p>Coming soom...</p>
            <div className="users-table">
                {
                    users.map(
                        user => {
                            return (
                                <div key={`user-${user.id}`} className="users-row">
                                    <div className="users-item">{user.name}</div>
                                    <div className="users-item">{user.role}</div>
                                    <div className="users-item">{user.active}</div>
                                </div>
                            )
                        }
                    )
                }
            </div>
            <Link to="/gpt">Back to GPT</Link>
        </section>
    );
}

export default UserManagement;
