import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { pdfjs, Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { getCookie } from '../../util/cookie';
import { physGPTServer } from '../../api/axios';

import "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/'
};

const PDFViewer = () => {
    const [numPages, setNumPages] = useState<number>();
    const [searchParams] = useSearchParams();
    const [pdfFile, setPdfFile] = useState<string>();
    const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get('page')));

    console.log('page:', currentPage)

    useEffect(() => {
        const get_pdf = async () => {
            physGPTServer.defaults.headers.get['X-Api-Key'] = getCookie('sessionKey')
            const response = await physGPTServer.get(`/pdf?file=${searchParams.get('file')}`);
            setPdfFile(response?.data?.file);
        }
        get_pdf();
    }, [searchParams]);

    const handleLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
        setNumPages(nextNumPages);
    };

    const handlePrevPageClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPageClick = () => {
        if ((numPages - currentPage) > 0) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <section className="pdf-viewer-container">
            <Document className="pdf-viewer" file={`data:application/pdf;base64,${pdfFile}`} onLoadSuccess={handleLoadSuccess} options={options}>
                <div className="pdf-page-number">{`${currentPage} of ${numPages}`}</div>
                <div className="prev-page-container">
                    <div className={`prev-page${currentPage > 1 ? '' : ' hidden'}`} onClick={handlePrevPageClick}>
                        {"<"}
                    </div>
                </div>
                <div className="pdf-viewer-page-container">
                    <Page key={`page-${currentPage}`}
                        pageNumber={currentPage}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}>
                    </Page>
                </div>
                <div className="next-page-container">
                    <div className={`next-page${currentPage < numPages ? '' : ' hidden'}`} onClick={handleNextPageClick}>
                        {">"}
                    </div>
                </div>

            </Document>
        </section>
    );
}

export default PDFViewer
